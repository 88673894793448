<template>
  <v-container fluid class="py-6">
    <v-row v-if="verifying == verifying_enum.Waiting">
      <v-col md="8" sm="10" class="mx-auto">
        <v-card class="card-shadow border-radius-xl my-12">
          <v-card-title class="justify-center">
            <h6 class="text-typo text-h3 font-weight-bold mb-2">
              {{ $t("Verify Mail Sent") }}
            </h6>
          </v-card-title>
          <div class="justify-center px-6 py-6">
            <v-row>
              <v-col cols="12" class="text-center">
                <h5 class="text-h4 mb-2 text-typo font-weight-bold">
                  認證信已經發送至您的信箱
                </h5>
                <h5 class="text-h4 mb-2 text-typo font-weight-bold">
                  {{ $route.query.email }}
                </h5>
                <p></p>
                <p class="text-secondary text-h5">遇到任何問題，請聯繫我們:</p>
                <h6 class="text-secondary text-h5 font-weight-bold mb-0">
                  email:
                  <span class="text-dark">support@dandelion.rest</span>
                </h6>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="verifying == verifying_enum.Verifying">
      <v-col md="8" sm="10" class="mx-auto">
        <v-card class="card-shadow border-radius-xl my-12">
          <v-card-title class="justify-center">
            <h6 class="text-typo text-h3 font-weight-bold mb-2">
              {{ $t("Account Verify") }}
            </h6>
          </v-card-title>
          <div class="px-6 py-6 text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div class="justify-center px-6 py-6 mt-md-8 mt-6">
            <v-row>
              <v-col cols="12" class="text-center">
                <h5 class="text-h4 mb-2 text-typo font-weight-bold">
                  請稍後 帳號驗證中
                </h5>
                <p class="text-secondary text-h5">遇到任何問題，請聯繫我們:</p>
                <h6 class="text-secondary text-h5 font-weight-bold mb-0">
                  email:
                  <span class="text-dark">support@dandelion.rest</span>
                </h6>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="verifying == verifying_enum.Done">
      <v-col md="8" sm="10" class="mx-auto">
        <v-card class="card-shadow border-radius-xl my-12">
          <v-card-title class="justify-center">
            <h6 class="text-typo text-h3 font-weight-bold mb-2">
              {{ $t("Account Success") }}
            </h6>
          </v-card-title>
          <v-row class="justify-center d-flex">
            <v-col lg="4" md="7" class="mx-auto">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="#5e72e4"
                small
                @click="toLogin()"
                >{{ $t("GO TO Login") }}</v-btn
              >
            </v-col>
          </v-row>
          <div class="justify-center px-6 py-6 mt-md-8 mt-6">
            <v-row>
              <v-col cols="12" class="text-center">
                <h5 class="text-h4 mb-2 text-typo font-weight-bold">感謝您</h5>
                <p class="text-secondary text-h5">遇到任何問題，請聯繫我們:</p>
                <h6 class="text-secondary text-h5 font-weight-bold mb-0">
                  email:
                  <span class="text-dark">support@dandelion.rest</span>
                </h6>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="verifying == verifying_enum.Fail">
      <v-col md="8" sm="10" class="mx-auto">
        <v-card class="card-shadow border-radius-xl my-12">
          <v-card-title class="justify-center">
            <h6 class="text-typo text-h3 font-weight-bold mb-2">
              {{ $t("Account Fail") }}
            </h6>
          </v-card-title>
          <v-row class="justify-center d-flex"> </v-row>
          <div class="justify-center px-6 py-6 mt-md-8 mt-6">
            <v-row>
              <v-col cols="12" class="text-center">
                <h5 class="text-h4 mb-2 text-typo font-weight-bold">
                  感謝您的註冊
                </h5>
                <p class="text-secondary text-h5">遇到任何問題，請聯繫我們:</p>
                <h6 class="text-secondary text-h5 font-weight-bold mb-0">
                  email:
                  <span class="text-dark">support@dandelion.rest</span>
                </h6>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { verifying_enum } from "@/definitions.js";

export default {
  name: "Verify",
  data() {
    return {
      verifying_enum: verifying_enum,
      verifying: verifying_enum.Verifying,
    };
  },
  mounted() {
    if (this.$route.query.token) {
      this.verify_token(this.$route.query.token);
    } else if (this.$route.query.email) {
      this.verifying = verifying_enum.Waiting;
    } else {
      this.verifying = verifying_enum.Fail;
    }
  },
  methods: {
    verify_token(token) {
      let currentObj = this;
      if (token != null) {
        let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/verify/${token}`;

        this.axios
          .get(url)
          .then(function (response) {
            console.log(response);
            setTimeout(() => {
              currentObj.verifying = verifying_enum.Done;
            }, 1000);
          })
          .catch(function (error) {
            console.log(error);
            currentObj.verifying = verifying_enum.Fail;
          });
      } else {
        console.log("Show 404");
        currentObj.verifying = verifying_enum.Fail;
      }
    },
    toLogin() {
      this.$router.push({ name: "SignIn" });
    },
  },
};
</script>
